<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />
      <v-card-text>
        <p
          v-if="resource"
          class="mt-4"
        >
          The items below cannot be updated once created.
        </p>

        <v-select
          v-model="form.section_id"
          :items="siteSectionsList"
          item-text="key"
          item-value="id"
          label="Select Section"
          outlined
          hide-details
          :disabled="!!resource"
          class="mt-5"
        ></v-select>

        <v-select
          v-if="siteItemRulesList"
          v-model="form.type"
          :items="siteItemRulesList.elements"
          label="Select Type"
          :rules="[required]"
          outlined
          hide-details
          class="mt-5"
          :disabled="!!resource"
        ></v-select>

        <v-select
          v-model="form.parent_id"
          :items="filteredSiteItemsList"
          item-text="key"
          item-value="id"
          label="Select Parent Item"
          outlined
          hide-details
          :disabled="!!resource"
          class="mt-5"
        ></v-select>

        <v-text-field
          v-model="form.key"
          label="Key"
          outlined
          :rules="[required]"
          :disabled="!!resource"
          class="mt-5"
        />

        <hr class="mt-4 mb-4">
        <!-- asset -->
        <!-- <v-select v-else-if=" key='src' && form.type =='image' " :items="siteAssetsList" -->
        <!-- <v-select
          v-model="form.asset_id"
          :items="siteAssetsList"
          label="Asset - associate item with an asset"
          item-value="id"
        >
          <template #selection="{ item }">
            <img
              :src="getImageUrl(item)"
              :style="{ maxWidth: '30px', maxHeight: '30px', marginRight:'10px' }"
            >{{ item.key }}
          </template>

          <template #item="{ item }">
            <img
              :src="getImageUrl(item)"
              :style="{ maxWidth: '30px', maxHeight: '30px', marginRight:'10px' }"
            >
            <div
              class="v-list-item__title"
              style="word-wrap: break-word;"
            >
              {{ item.notes }}
            </div>
          </template>
        </v-select> -->

        <v-select
          v-model="form.feature_id"
          :items="featuresOptions"
          item-text="key"
          item-value="id"
          label="Required Feature (empty=all visible)"
          clearable
          class="mt-2"
        ></v-select>

        <!-- {{ selectedItemRules }} -->

        <div v-if="selectedItemRules">
          <template v-for="(value, key) in selectedItemRules">
            <div v-if="key === 'class'">
              <!-- Do nothing now. -->
            </div>
            <div v-else-if="key === 'style'">
              <!-- Do nothing now. -->
            </div>
            <!-- <v-select
              v-if="key=='class'"
              v-model="form.element[key]"
              :label="key"
              outlined
              multiple
              class="mt-5"
              :items="classOptions"
            /> -->
            <!--
            <div v-else-if="key=='style'">
              <div
                v-for="(styleObj, index) in styleModel"
                :key="index"
                class="d-flex align-center"
              >
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="styleObj.property"
                      label="Property"
                      outlined
                      class="mt-5"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="styleObj.value"
                      label="Value"
                      outlined
                      class="mt-5"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      color="primary"
                      class="mt-5"
                      @click="addStyleRow"
                    >
                      +
                    </v-btn>
                    <v-btn
                      color="error"
                      class="mt-5"
                      @click="removeStyleRow(index)"
                    >
                      -
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div> -->

            <!-- text -->
            <v-textarea
              v-else-if="key=='content' && !value.includes('array')"
              v-model="form.element[key]"
              :label="key"
              outlined
            />
            <!-- href -->

            <v-text-field
              v-else-if="value.includes('string')"
              v-model="form.element[key]"
              label="href"
              hint="To Path after click. Can leave empty if not a link."
              outlined
              class="mt-5"
            />

            <!-- src -->
            <translatable-input
              v-else-if="key=='src' && value.includes('array')"
              v-model="form.element[key]"
              label="image src (external)"
              hint="If an asset (internal) is associated, it will override the external src."
              outlined
              :disabled="!!form.asset_id"
            />

            <!-- text -->
            <translatable-editor
              v-else-if="key=='text' && value.includes('array')"
              v-model="form.element[key]"
              :label="key"
              outlined
            />

            <v-text-field
              v-else-if="value.includes('array')"
              v-model="form.element[key]"
              :label="key"
              outlined
              class="mt-5"
            />

            <!-- any object, not displaying -->
            <div
              v-else
              v-model="form.element[key]"
              :label="key"
              outlined
              class="mt-5"
            />
          </template>
        </div>

        <v-select
          v-model="form.asset_id"
          :items="siteAssetsList"
          item-text="notes"
          item-value="id"
          label="Asset - associate image item with highly reusable & fixed image internal asset"
          :disabled="form.type !== 'image'"
        >
        </v-select>
        <!--
v-model="form.element[key]"
              :label="key" outlined multiple class="mt-5" :items="classOptions"  -->
        <v-text-field
          v-model="form.sort"
          type="number"
          label="Sort"
          outlined
          class="mt-5"
          hint="The sort position (ASC)"
        />

        <div class="d-flex align-center">
          <v-switch v-model="form.auth" />
          Login user visible

          <v-select
            v-if="form.auth"
            v-model="form.auth_state"
            :items="authStateOptions"
            item-text="title"
            item-value="value"
            label="When"
            clearable
            class="ml-2"
          ></v-select>
        </div>

        <div class="d-flex align-center">
          <v-switch v-model="form.guest" />
          Guest visible
        </div>

        <div class="d-flex align-center">
          <v-switch v-model="form.active" />
          Active
        </div>

          <!-- <v-select
          v-model="form.sync_ids"
          :items="distributorOptions"
          item-text="title"
          item-value="value"
          label="Select Distributor"
          outlined
          chips
          multiple
          hide-details
        >
        </v-select>

        <p>Note: leave empty to allow all distributors to see them.</p> -->
        </v-select>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableEditor, TranslatableInput, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import useFeatureList from '@/modules/feature/views/useFeatureList'
import useSites from '@/modules/site/views/useSites'
import router from '@/router'
import { required } from '@core/utils/validation'
import { computed, ref, watch } from '@vue/composition-api'
import useSiteList from '../../site-list/useSiteList'

export default {
  components: {
    TranslatableInput,
    TranslationLocales,
    TranslatableEditor,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const {
      sitesList,
      loadSites,
      sitePagesList,
      loadSitePages,
      siteSectionsList,
      loadSiteSections,
      siteItemsList,
      loadSiteItems,
      siteItemRulesList,
      loadSiteItemRules,
      siteAssetsList,
      loadSiteAssets,
    } = useSites()
    loadSiteSections({ page_id: router.currentRoute.query.page_id })
    loadSiteItems({ section_id: router.currentRoute.query.section_id })
    loadSiteItemRules()
    loadSiteAssets()

    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const styleField = (name, initValue) => {
      if (props.resource && props.resource[name].style) {
        console.log('props.resource[name].style', props.resource[name].style)

        // check if the style object is empty, if true -> create the initValue
        if (Object.keys(props.resource[name].style).length === 0) {
          return {
            [name]: props.resource ? props.resource[name] : initValue,
          }
        }

        // to extract properties and values
        const result = Object.keys(props.resource[name].style).map(property => ({
          property,
          value: props.resource[name].style[property],
        }))
        styleModel.value = result
      }

      return {
        [name]: props.resource ? props.resource[name] : initValue,
      }
    }

    const resetStyleField = (name, initValue) => ({
      [name]: initValue,
    })

    // const syncField = (name, initValue) => ({
    //   [name]: props.resource ? props.resource.distributors.map(d => d.id) : initValue,
    // })

    const rules = props.resource ? props.resource.rules : false

    const styleModel = ref([
      {
        property: '',
        value: '',
      },
    ])

    const initialForm = {
      ...field('key', ''),
      ...field('guest', true),
      ...field('auth', true),
      ...field('auth_state', null),
      ...field('active', true),
      ...field('section_id', null),
      ...field('parent_id', null),
      ...field('feature_id', null),
      ...field('type', null),
      ...field('sort', 1),
      ...field('asset_id', null),
      ...styleField('element', {
        style: [
          {
            property: '',
            value: '',
          },
        ],
        class: [],
        src: useTranslatable(null),
        text: useTranslatable(null),
      }),

      // ...syncField('sync_ids', []),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const filteredSiteItemsList = computed(() => {
      if (!siteItemsList.value || !Array.isArray(siteItemsList.value)) {
        return [
          {
            id: null,
            key: 'None',
          },
        ]
      }

      return [
        {
          id: null,
          key: 'None',
        },
      ].concat(siteItemsList.value.filter(item => item.section_id === form.value.section_id))
    })

    const selectedItemRules = computed(() => {
      if (!form.value.type || !siteItemRulesList.value) {
        return null
      }

      return siteItemRulesList.value.rules[form.value.type]
    })

    const classOptions = ref(['right-to-left', 'left-to-right'])

    const { featuresOptions, loadFeaturesData } = useFeatureList()
    loadFeaturesData({ take: 999 })
    const { authStateOptions } = useSiteList()

    // const { distributorOptions } = useDistributor()

    const addStyleRow = () => {
      styleModel.value.push({
        property: '',
        value: '',
      })
    }

    const removeStyleRow = index => {
      if (index > 0) styleModel.value.splice(index, 1)
    }

    const validate = () => {
      if (formElem.value.validate()) {
        // style convert
        const styleObj = convertStylesToObject(styleModel.value)
        form.value.element.style = styleObj

        // remove unused elements
        filterFormElement()

        emit('submit', form.value)
      }
    }

    const convertStylesToObject = stylesArray => stylesArray.reduce((result, styleObj) => {
      if (!styleObj.property && !styleObj.value) {
        console.log(result)

        return result
      }
      result[styleObj.property] = styleObj.value

      return result
    }, {})

    const filterFormElement = () => {
      const filteredElement = {}
      for (const key in form.value.element) {
        if (selectedItemRules.value.hasOwnProperty(key)) {
          filteredElement[key] = form.value.element[key]
        }
      }
      console.log('filteredElement', filteredElement)
      form.value.element = filteredElement
    }

    watch(
      () => form.value.type,
      () => {
        console.log('form.type', form.value.type)
        const resetElement = resetStyleField('element', {
          style: [
            {
              property: '',
              value: '',
            },
          ],
          class: [],
          src: useTranslatable(null),
          text: useTranslatable(null),
        })
        console.log(resetElement)
        form.value.element = resetElement.element
      },
    )

    const getImageUrl = item => item.instance.detail

    return {
      form,
      formElem,
      validate,
      required,

      siteSectionsList,
      filteredSiteItemsList,
      siteItemRulesList,
      selectedItemRules,
      classOptions,
      featuresOptions,
      authStateOptions,
      addStyleRow,
      removeStyleRow,
      styleModel,
      siteAssetsList,
      getImageUrl,

      isReserved: props.resource && props.resource.reserved,

      // distributorOptions,
    }
  },
}
</script>
@/modules/feature/views/useFeatureList
